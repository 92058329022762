<template>
  <div class="bills-content">
<!--    <a-row>-->
<!--      <a-col :span="16">-->
<!--        <h4 class="text-primary"><font-awesome-icon icon="file-invoice-dollar" /> Счета</h4>-->
<!--      </a-col>-->
<!--      <a-col :span="8" class="text-right">-->
<!--        <a-button :loading="transactionsLoading" @click="getTransactions"><a-icon type="reload" /> Обновить</a-button>-->
<!--      </a-col>-->
<!--    </a-row>-->
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <span class="h5 mb-0"><a-icon type="tags" /> Promocodes</span>
        <div>
          <a-button type="primary" @click="showAddGroupModal">
            <a-icon type="plus" /> Add group
          </a-button>
<!--          <a-button :loading="groupsLoading" @click="getPromoGroups"><a-icon type="reload" /> Обновить</a-button>-->
        </div>
      </div>
      <a-table :columns="groupsColumns" :dataSource="groupsData" :pagination="true">
        <span slot="name" slot-scope="name, record">
          <router-link
            tag="a"
            class="button"
            :to="{name: 'promocode-list', params: { groupId: record.id }}"
          >
            <a href="javascript:">{{ name }}</a>
          </router-link>
        </span>
        <span slot="currency" slot-scope="currency">
          <span> {{ currency.name }} </span>
        </span>
        <span slot="amount" slot-scope="amount, record">
          <span> {{ amount }} {{ record.currency.abbr }} </span>
        </span>
        <span slot="counter_free" slot-scope="counter">
          <span> <a-icon type="tags" class="text-success"/> {{ counter.free }} </span>
        </span>
        <span slot="counter_reserved" slot-scope="counter">
          <span> <a-icon type="tags" class="text-warning"/> {{ counter.reserved }} </span>
        </span>
        <span slot="counter_activated" slot-scope="counter">
          <span> <a-icon type="tags" class="text-danger"/> {{ counter.activated }} </span>
        </span>
        <span slot="operation" slot-scope="text, record">
          <div class="text-right">
<!--            <span class="ml-3">-->
<!--              <a-tooltip title="Редактировать" placement="bottom">-->
<!--                <a href="javascript:" class="font-weight-bolder font-size-18"><a-icon type="edit" theme="filled" /></a>-->
<!--              </a-tooltip>-->
<!--            </span>-->
            <span>
              <a-tooltip title="Activate / Deactivate" placement="bottom">
                <a-switch v-model="record.is_active" size="small" :groupId="record.id" @change="groupActiveState" />
              </a-tooltip>
            </span>
            <span class="ml-3">
              <a-tooltip title="Delete" placement="bottom">
                <a-popconfirm
                  title="Delete promo code group?"
                  @confirm="deleteGroup(record)"
                >
                  <a href="javascript:" class="font-weight-bolder font-size-18"><a-icon class="text-danger" type="delete" theme="filled" /></a>
                </a-popconfirm>
              </a-tooltip>
            </span>
          </div>
        </span>
      </a-table>
    </a-card>
    <a-modal
      v-model="addGroupModal"
      title="New Promocode Group"
      ok-text="Ok"
      cancel-text="Cancel"
      @ok="addGroupSubmit"
      :footer="null"
      class="add-group-modal"
      width="520px"
      :destroyOnClose="true"
    >
      <a-form-model
        ref="addGroupForm"
        :model="addGroupForm"
        :rules="addGroupRules"
      >
        <a-form-model-item ref="name" label="Group Name" prop="name">
          <a-input v-model="addGroupForm.name" placeholder="" />
        </a-form-model-item>
        <a-row>
          <a-col :span="12" class="pr-2">
            <a-form-model-item required label="Currency" prop="currency_id">
              <a-select v-model="addGroupForm.currency_id" :options="groupCurrencies"  />
            </a-form-model-item>
          </a-col>
          <a-col :span="12" class="pl-2">
            <a-form-model-item ref="amount" label="Amount for Promocodes" prop="amount">
              <a-input-number
                v-model="addGroupForm.amount"
                :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                style="width: 100%"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-row>
        <a-col :span="24" class="text-right">
          <div class="mt-5">
            <a-button type="primary" @click="addGroupSubmit" :loading="addGroupLoading">Add</a-button>
            <a-button style="margin-left: 1rem;" @click="closeAddGroupModal">Cancel</a-button>
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import moment from 'moment-timezone'

const groupsColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '7%',
  },
  {
    title: 'Group',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    width: '7%',
    scopedSlots: { customRender: 'currency' },
  },
  {
    title: 'Sum',
    dataIndex: 'amount',
    key: 'amount',
    sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Available',
    dataIndex: 'counter',
    key: 'counter_all',
    width: '10%',
    scopedSlots: { customRender: 'counter_free' },
  },
  {
    title: 'Reserved',
    dataIndex: 'counter',
    key: 'counter_reserved',
    width: '15%',
    scopedSlots: { customRender: 'counter_reserved' },
  },
  {
    title: 'Activated',
    dataIndex: 'counter',
    key: 'counter_activated',
    width: '15%',
    scopedSlots: { customRender: 'counter_activated' },
  },
  {
    title: 'Action',
    dataIndex: 'operation',
    align: 'right',
    width: '10%',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'index',
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      groupsData: [],
      groupsColumns,
      moment,
      groupsLoading: false,
      groupCurrencies: [],
      addGroupLoading: false,
      addGroupModal: false,
      addGroupForm: {
        name: '',
        currency_id: undefined,
        amount: undefined,
        is_active: 1,
      },
      addGroupRules: {
        name: [{ required: true, message: 'Enter the name of the group', trigger: 'change' }],
        currency_id: [{ required: true, message: 'Enter the currency for the group', trigger: 'change' }],
        amount: [{ required: true, message: 'Enter the amount for the group', trigger: 'change' }],
      },
    }
  },
  mounted () {
    this.getPromoGroups()
    this.getCurrencies()
  },
  methods: {
    showAddGroupModal() {
      this.addGroupModal = true
    },
    closeAddGroupModal() {
      this.addGroupModal = false
    },
    groupActiveState(checked, event) {
      const groupId = event.target.getAttribute('groupId')
      const url = '/admin/promocodes/groups/' + groupId + '/active/' + +checked
      apiClient.get(url).then((response) => {
        this.$notification.success({
          message: 'Group status changed',
          description: response.data.data.name,
        })
        console.log(groupId + ' switched to ' + +checked)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Status change error',
          description: error.message,
        })
      })
    },
    addGroupSubmit() {
      this.$refs.addGroupForm.validate(valid => {
        if (valid) {
          // ---
          this.addGroupLoading = true
          const url = '/admin/promocodes/groups'
          return apiClient.post(url, this.addGroupForm).then((response) => {
            this.$notification.success({
              message: 'New group of promo codes added',
              description: response.data.data.name,
            })
            this.groupsData.push(response.data.data)
            this.addGroupLoading = false
            this.closeAddGroupModal()
          }).catch(error => {
            console.log(error)
            this.$notification.error({
              message: 'Error creating group',
              description: error.message,
            })
            this.addGroupLoading = false
          })
        }
      })
    },
    deleteGroup(group) {
      const url = '/admin/promocodes/groups/' + group.id
      apiClient.delete(url).then((response) => {
        console.log(response.data)
        this.$notification.success({
          message: 'Promo code group removed',
          description: group.name,
        })
        const accIndex = this.groupsData.findIndex(obj => obj.id === group.id)
        this.groupsData.splice(accIndex, 1)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error deleting promo code group',
          description: error.message,
        })
      })
    },
    getPromoGroups() {
      this.groupsLoading = true
      const url = '/admin/promocodes/groups/all'
      apiClient.get(url).then((response) => {
        this.groupsData = response.data.data
        this.groupsLoading = false
      }).catch(error => {
        console.log(error)
        this.groupsLoading = false
      })
    },
    getCurrencies() {
      const url = '/admin/promocodes/groups/currencies'
      apiClient.get(url).then((response) => {
        this.groupCurrencies = response.data.data.map(function(row) {
          return { value: row.id, label: row.name + ' (' + row.abbr + ')' }
        })
        console.log(this.groupCurrencies)
      }).catch(error => { console.log(error) })
    },
  },
}
</script>

<style scoped>

</style>
